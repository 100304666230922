// eslint-disable-next-line import/prefer-default-export
export const shouldShowTopBarSlider = (displaysOn, pathname, hideTopBarSlider) => {
  // If on cart or checkout, hide
  if (hideTopBarSlider) return false
  const plpPages = ['furniture', 'mattress', 'search']
  const normalizedPath = pathname.toLowerCase()
  if (displaysOn?.includes('home') && displaysOn?.includes('plp') && displaysOn?.includes('pdp')) return true
  const isPlpPage = plpPages.some(keyword => normalizedPath.includes(keyword))
  const isPdpPage = normalizedPath.includes('product')
  if (isPdpPage) {
    return displaysOn?.includes('pdp')
  }
  if (isPlpPage) {
    return displaysOn?.includes('plp')
  }
  return displaysOn?.includes('home')
}
