// dependencies
import React, { useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import {
  styled,
  Badge,
  IconButton,
  AppBar as MuiAppBar,
  Box,
  Toolbar as MuiToolbar,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import ActiveFavoriteIcon from '@mui/icons-material/Favorite'
import TopBarInfoSlider from '@templates/strapi-cms/content-types/TopBarInfoSlider'
// hooks
// assets
import LogoDesktopSvg from '@shared/svgs/logo'
// constants
import useStore from '@store'
// components
import StrapiLink, { ButtonLink } from '@templates/strapi-cms/content-types/Link'
import SearchForm from '@shared/search-form'
import { useLocation } from '@gatsbyjs/reach-router'
import useStaticTopBarQuery from '@hooks/useStaticTopBarQuery'
import { shouldShowTopBarSlider } from '@components/header/helpers'

import DesktopNavigation from '../Navigation'
import MiniCartDropdown from './MiniCartDropdown'
import MyAccountDropDown from './MyAccountDropDown'
import MyStore from '../../MyStore'

const StyledAppBar = styled(MuiAppBar)`
  align-items: center;
  border-right: none;
  border-left: none;
  border-top: none;
`

const viewCartTestIds = {
  viewCartLink: 'cartButton',
}

const AccountContent = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  'a:focus': {
    outline: 'none !important',
  },
})

const LogoLink = styled(StrapiLink)(({ theme }) => ({
  display: 'block',
  width: '255px',
  marginRight: theme.spacing(3),
}))

const Toolbar = styled(MuiToolbar)({
  maxWidth: '104rem',
  width: '100%',
  minHeight: 'unset !important',
  justifyContent: 'space-between',

  '&.main-header': {
    height: '70px',
    maxWidth: '1440px',
    padding: '1rem',
  },
  '&.meganav': {
    maxWidth: 'unset !important',
    padding: 0,
  },
})

const StyledMyStore = styled(MyStore)({
  margin: '0 1rem 0 0',
})
const StyledDiv = styled('div')({
  backgroundColor: 'black',
  color: 'white',
  minHeight: '50px',
})

const isFavoriteActive = () => {
  if (typeof window !== 'undefined') {
    return window?.location?.pathname?.includes?.('/favorites')
  }
  return false
}

const iconSx = { fontSize: '24px !important', color: 'primary.dark' }

export const DesktopAppBar = ({ isCheckout, onSetBackdrop, authData, hideTopBarSlider = false }) => {
  const { pathname } = useLocation()
  const initializeFavorites = useStore(store => store.favorites.initialize)
  const topBarData = useStaticTopBarQuery()
  const DisplaysOnList = topBarData?.strapiHeader?.TopBarInfoSlider?.TopBarInfoSlider?.DisplaysOnList?.strapi_json_value
  const showTopBarSlider = shouldShowTopBarSlider(DisplaysOnList, pathname, hideTopBarSlider)
  // const { user } = useFirebaseContext()
  const favorites = useStore(store => store.favorites.list)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const headerElement = typeof document !== 'undefined' && document.querySelectorAll('header.desktop-only')?.[0]
  useEffect(() => {
    initializeFavorites(authData.user)
  }, [initializeFavorites, authData.user])

  useLayoutEffect(() => {
    // eslint-disable-next-line no-undef
    const resizeObserver = new ResizeObserver(() => {
      if (typeof window !== 'undefined') {
        window.requestAnimationFrame(() => {
          const headerSize = headerElement?.offsetHeight ?? 83
          // update body paddingTop to prevent content from being hidden behind the header, only if existing paddingTop is different
          if (headerElement && document.body.style.paddingTop !== `${headerSize}px`) {
            document.body.style.paddingTop = `${headerSize}px`
          }
        })
      }
    })

    if (headerElement) resizeObserver.observe(headerElement)
    // eslint-disable-next-line consistent-return
    return () => resizeObserver?.disconnect()
  }, [pathname, headerElement])

  // Removes Desktop Hedear on browser runtime (when is mobile) but renders it at build time
  if (typeof window !== 'undefined' && !isDesktop) {
    return null
  }
  return (
    <StyledAppBar position="fixed" className="desktop-only">
      {showTopBarSlider && <TopBarInfoSlider data={topBarData} />}
      <Toolbar className="main-header">
        <LogoLink
          data={{
            InternalUrl: '/',
            title: 'Rooms To Go Home',
          }}
          trackingData={{
            category: 'header',
            action: 'logo click',
            label: 'logo',
          }}
        >
          <LogoDesktopSvg uniqueNameForId="desktop-header" />
        </LogoLink>
        {!isCheckout && (
          <a href="#primary-nav-desktop" className="skip">
            Skip to primary navigation
          </a>
        )}
        {isCheckout && (
          <Button
            component={ButtonLink}
            data-testid={viewCartTestIds.viewCartLink}
            data={{
              InternalUrl: '/cart',
              title: 'Return to cart',
            }}
            trackingData={{ category: 'header', action: 'return to cart click', label: 'return to cart' }}
          >
            Return to Cart
          </Button>
        )}
        {!isCheckout && (
          <>
            {/* LOCATION */}
            {/* <StoreLocator>
              <NavButton
                component={ButtonLink}
                data={{
                  InternalUrl: '/stores',
                  title: 'Stores',
                }}
                trackingData={{
                  category: 'header',
                  action: 'stores interaction',
                  label: 'go to stores',
                }}
                startIcon={<LocationIcon sx={iconSx} />}
              >
                <span className="text-wrapper">
                  <span className="btn-text underline">Stores</span>
                </span>
              </NavButton>
            </StoreLocator> */}
            <StyledMyStore />
            <Box sx={{ flexGrow: 1 }}>
              <SearchForm id="search-desktop" addClasses={{ active: true }} />
            </Box>
            <AccountContent>
              {/* ACCOUNT POPUP */}
              <MyAccountDropDown user={authData.user} data={authData.data} />
              {/* DELIVERY TO */}
              {/* <ShopAndDeliverTo /> */}
              {/* FAVORITES */}
              <IconButton
                component={ButtonLink}
                data={{
                  InternalUrl: '/favorites',
                  Title: 'Favorites',
                }}
                trackingData={{
                  category: 'header favorites',
                  action: 'favorites click',
                  label: 'go to favorites page',
                }}
                sx={{ ml: 2 }}
              >
                <span className="hide508">Favorites</span>
                <Badge badgeContent={favorites.size} color="primary">
                  {isFavoriteActive() ? (
                    <ActiveFavoriteIcon sx={iconSx} role="presentation" />
                  ) : (
                    <FavoriteBorderOutlinedIcon sx={iconSx} role="presentation" />
                  )}
                </Badge>
              </IconButton>
              {/* CART POPUP */}
              <MiniCartDropdown />
            </AccountContent>
          </>
        )}
      </Toolbar>
      {!isCheckout && (
        <Toolbar className="meganav">
          <DesktopNavigation onSetBackdrop={onSetBackdrop} />{' '}
        </Toolbar>
      )}
    </StyledAppBar>
  )
}

DesktopAppBar.propTypes = {
  onSetBackdrop: PropTypes.func.isRequired,
  isCheckout: PropTypes.bool,
  hideTopBarSlider: PropTypes.bool,
  authData: PropTypes.any,
}

export default DesktopAppBar
