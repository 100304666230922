import { graphql, useStaticQuery } from 'gatsby'

export const StrapiTopBarQuery = graphql`
  query StrapiTopBarQuery {
    strapiHeader {
      id
      TopBarInfoSlider {
        id
        testId
        TopBarInfoSlider {
          id
          testId
          Title
          DisplaysOnList {
            strapi_json_value
          }
          TopBarInfo {
            id
            testId
            TopBarInfo {
              id
              testId
              Title
              Header
              Icon {
                ...StrapiImageFragment
              }
              SecondaryLink {
                Link {
                  ...StrapiLinkFragment
                }
              }
              InfoBarLink {
                Link {
                  ...StrapiLinkFragment
                }
              }
              BackgroundColor {
                ColorHex
              }
              TextColor {
                ColorHex
              }
              ArrowColor {
                ColorHex
              }
              DisableUnderline
              Variant
            }
          }
        }
      }
    }
  }
`

const useStaticTopBarQuery = () => {
  const data = useStaticQuery(StrapiTopBarQuery)

  return data
}

export default useStaticTopBarQuery
