// dependencies
import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { applyActionCode, getAuth } from 'firebase/auth'
import { styled, Fade, useMediaQuery } from '@mui/material'
import { validateCouponFromLocalStorage } from '@services/coupon'
// redux
import { setIsMobile as setIsMobileAction, setIsLandscape as setIsLandscapeAction } from '@redux/modules/global'
// helpers
import { isMobileWindow, isLandscapeWindow } from '@helpers/deviceWindow'
// hooks
import { useUserQuery } from '@hooks/auth'
import { useFirebaseContext } from '@context/firebaseContext'
import { useNotifications } from '@context/notifications'
// components
import DesktopHeader from './desktop/AppBar'
import MobileHeader from './mobile/AppBar'

const StyledHeader = styled('div')`
  .mobile-only {
    ${p => p.theme.breakpoints.up('md')} {
      display: none;
    }
  }
  .desktop-only {
    ${p => p.theme.breakpoints.down('md')} {
      display: none;
    }
  }
`

const Backdrop = styled('div')`
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  width: 100vw;
  height: 100vh;
  overflow: overflow;
  position: fixed;
  top: 0;
  left: 0;
`

const Header = ({ checkout = false, hideTopBarSlider = false }) => {
  const [open, setOpen] = useState(false)
  const [shouldDisplayMobileHeader, setShouldDisplayMobileHeader] = useState(true)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const dispatch = useDispatch()
  const onSetBackdrop = (value = false) => setOpen(value)
  const auth = getAuth()
  const { data } = useUserQuery()
  const { user } = useFirebaseContext()
  const { enqueue } = useNotifications()

  const searchParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : null

  const updateWindowDimensions = useCallback(() => {
    dispatch(setIsMobileAction(isMobileWindow()))
    dispatch(setIsLandscapeAction(isLandscapeWindow()))
  }, [dispatch])

  useEffect(() => {
    const verifyAccount = () => {
      const oobCode = searchParams.get('oobCode')
      const mode = searchParams.get('mode')
      if (oobCode && auth && mode === 'verifyEmail') {
        applyActionCode(auth, oobCode)
          .then(() => {
            setTimeout(() => {
              enqueue(
                {
                  variant: 'sectioned',
                  body: 'Email verified successfully',
                  severity: 'success',
                  sectionedColors: {
                    primary: '#00854a',
                    secondary: '#E6F2EC',
                  },
                },
                500,
              )
            })
            try {
              auth.currentUser.reload()
            } catch (err) {
              console.error(err)
            }
          })
          .catch(error => {
            setTimeout(() => {
              enqueue(
                {
                  variant: 'filled',
                  body: 'There was a problem verifying your account.',
                  severity: 'error',
                },
                500,
              )
              console.error(error)
            })
          })
      }
    }
    verifyAccount()
    return () => clearTimeout()
    // eslint-disable-next-line
  }, [auth, enqueue])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateWindowDimensions()
      window.addEventListener('resize', updateWindowDimensions)
      return () => window.removeEventListener('resize', updateWindowDimensions)
    }
    return () => {}
  }, [updateWindowDimensions])

  useEffect(() => {
    validateCouponFromLocalStorage()
  }, [])
  useLayoutEffect(() => {
    setShouldDisplayMobileHeader(isMobile)
  }, [isMobile])

  return (
    <>
      <Fade in={!checkout && open} timeout={400} unmountOnExit>
        <Backdrop />
      </Fade>
      <StyledHeader>
        <DesktopHeader
          isCheckout={checkout}
          onSetBackdrop={onSetBackdrop}
          authData={{ data, user }}
          hideTopBarSlider={hideTopBarSlider}
        />
        {shouldDisplayMobileHeader && <MobileHeader isCheckout={checkout} hideTopBarSlider={hideTopBarSlider} />}
      </StyledHeader>
    </>
  )
}

Header.propTypes = {
  checkout: PropTypes.bool,
  hideTopBarSlider: PropTypes.bool,
}

export default Header
