import PropTypes, { object } from 'prop-types'
import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper-v9/react'
import { Navigation, Autoplay, A11y } from 'swiper-v9'
import { Collapse, styled } from '@mui/material'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import TopBarInfoSlide from './TopBarInfoSlide'
import 'swiper-v9/css'
import 'swiper-v9/css/navigation'

const SliderContainer = styled('section')(({ backgroundColor, isIconOnTop }) => ({
  height: isIconOnTop ? '53px' : '48px',
  width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor,
}))

const StyledNavButton = styled('button')(({ theme, color }) => ({
  height: '48px',
  width: '48px',
  color,
  zIndex: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px',
  outline: 'none',
  '&:focus, &:focus-visible': {
    outline: 'none !important',
    boxShadow: 'none !important',
  },
  [theme.breakpoints.down('md')]: {
    height: '38px',
  },
}))

const TopBarInfoSlider = ({ data, isDrawerOpen, isMobile = false }) => {
  const slides = data?.strapiHeader?.TopBarInfoSlider?.TopBarInfoSlider?.TopBarInfo
  const [currentSlide, setCurrentSlide] = useState({
    backgroundColor: slides?.[0]?.TopBarInfo?.BackgroundColor?.ColorHex || '#07263B',
    color: slides?.[0]?.TopBarInfo?.ArrowColor?.ColorHex || slides?.[0]?.TopBarInfo?.TextColor?.ColorHex || '#ffffff',
    variant: slides?.[0]?.TopBarInfo?.Variant || 'IconAdjacent',
  })
  const showSliderButtons = slides?.length > 1
  const isIconOnTop = currentSlide.variant === 'IconOnTop' && isMobile

  const updateCurrentSlide = (slide, index) => {
    const { BackgroundColor, TextColor, ArrowColor, Variant, Header } = slide.TopBarInfo
    const backgroundColor = BackgroundColor?.ColorHex || '#07263B'
    const color = ArrowColor?.ColorHex || TextColor?.ColorHex || '#ffffff'
    const variant = Variant || 'IconAdjacent'
    const header =
      Header ||
      `${data?.strapiHeader?.TopBarInfoSlider?.TopBarInfoSlider?.Title} Slider: Slide ${index} of ${slides.length}`
    setCurrentSlide({ backgroundColor, color, variant, header, index })
  }

  const handleSlideChange = swiper => {
    const activeSlide = slides[swiper.realIndex]
    if (activeSlide) updateCurrentSlide(activeSlide, swiper.realIndex)
  }

  if (!slides) return null

  return (
    <Collapse in={isMobile ? !isDrawerOpen : true} easing={{ enter: 'ease-in' }} timeout={200} unmountOnExit>
      <SliderContainer
        backgroundColor={currentSlide.backgroundColor}
        isIconOnTop={isIconOnTop}
        aria-label="Top Bar Slider"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            maxWidth: '1440px',
          }}
        >
          {showSliderButtons && (
            <StyledNavButton className="tbs-swiper-prev" color={currentSlide.color} aria-label="Previous slide">
              <ArrowBackIos />
            </StyledNavButton>
          )}
          <Swiper
            modules={[Autoplay, Navigation, A11y]}
            slidesPerView={1}
            spaceBetween={100}
            onSlideChange={handleSlideChange}
            a11y={{
              enabled: true,
              slideLabelMessage: currentSlide.header,
            }}
            navigation={{
              nextEl: '.tbs-swiper-next',
              prevEl: '.tbs-swiper-prev',
            }}
            onBeforeInit={swiper => {
              swiper.params.navigation.prevEl = '.tbs-swiper-prev'
              swiper.params.navigation.nextEl = '.tbs-swiper-next'
            }}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            loop
            style={{ height: '100%' }}
          >
            {slides.map((slide, index) => {
              const isActiveSlide = index === currentSlide.index
              return (
                <SwiperSlide key={slide.id} style={{ height: '100%' }} aria-hidden={!isActiveSlide}>
                  <TopBarInfoSlide data={slide.TopBarInfo} isMobile={isMobile} />
                </SwiperSlide>
              )
            })}
          </Swiper>
          {showSliderButtons && (
            <StyledNavButton className="tbs-swiper-next" color={currentSlide.color} aria-label="Next slide">
              <ArrowForwardIos />
            </StyledNavButton>
          )}
        </div>
      </SliderContainer>
    </Collapse>
  )
}

TopBarInfoSlider.propTypes = {
  data: PropTypes.shape({
    strapiHeader: PropTypes.shape({
      TopBarInfoSlider: PropTypes.shape({
        TopBarInfoSlider: PropTypes.shape({
          TopBarInfo: PropTypes.arrayOf(object),
          Title: PropTypes.string.isRequired,
        }),
      }),
    }),
  }),
  isDrawerOpen: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
}

export default TopBarInfoSlider
