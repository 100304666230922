import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { ButtonLink } from '@templates/strapi-cms/content-types/Link'
import { useDispatch } from 'react-redux'
import { AppBar, Toolbar, useTheme, Typography, Badge, IconButton, Button, styled } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import useStore from '@store'
import RTGLogo from '@shared/svgs/logo'
import { LeftSideBtn, LogoWrapper, LogoWrapperCheckout } from '@components/header/mobile/Styled'
import Cart from '@components/header/mobile/AppBar/Cart'
import MobileDrawer from '@components/header/mobile/Drawer'
import PropTypes from 'prop-types'
import { usePage } from '@hooks/usePage'
import { usePrevious } from '@hooks/usePrevious'
import { isBodyLocked } from '@helpers/bodyscroll'

import ActiveFavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { setMobileShowMeganav } from '@redux/modules/global'
import TopBarInfoSlider from '@templates/strapi-cms/content-types/TopBarInfoSlider'
import useStaticTopBarQuery from '@hooks/useStaticTopBarQuery'

import MyStore from '../../MyStore'
import MobileSearch from '../../../../@rtg2022/components/molecules/AppBar/Search/MobileSearch'
import { shouldShowTopBarSlider } from '../../helpers'

const commonProps = {
  position: 'relative',
  opacity: 1,
  transition: '.2s all ease-in-out',
  '&.hidden': {
    maxHeight: '0!important',
    minHeight: '0!important',
    opacity: '0!important',
  },
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  ...commonProps,
  overflowY: 'hidden',
  minHeight: 0,
  maxHeight: '56px',
  [theme.breakpoints.down('sm')]: {
    minHeight: '64px',
    padding: '0 24px',
  },
  '*:not(#mobile-logo-header)': {
    svg: {
      height: '24px',
      width: '24px',
    },
  },
}))

const StyledMobileSearch = styled(MobileSearch)(() => ({
  ...commonProps,
  maxHeight: '58px',
}))

const StyledCheckoutLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  lineHeight: 0,
  padding: '24px 0',
  fontSize: '23px',
  fontWeight: 300,
  textTransform: 'uppercase',
}))

const MobileAppBar = ({ isCheckout, children, hideTopBarSlider = false }) => {
  const refToolbar = useRef()
  const refSearchBar = useRef()
  const [drawer, setDrawer] = useState(false)
  const [hiddenAppBarAlert, setHiddenAppBarAlert] = useState(false)
  const [showSearch, setShowSearch] = useState(!isCheckout)
  const favorites = useStore(store => store.favorites.list)
  const dispatch = useDispatch()
  const theme = useTheme()
  const topBarData = useStaticTopBarQuery()
  const DisplaysOnList = topBarData?.strapiHeader?.TopBarInfoSlider?.TopBarInfoSlider?.DisplaysOnList?.strapi_json_value

  const getHeaderStateByLocation = pathnameLocation => {
    if (pathnameLocation === '/')
      return {
        fadedComponent: refToolbar?.current,
        showToolbarButtons: {
          UP: ['stores', 'cart'],
          DOWN: ['stores', 'cart'],
        },
        hideAppBarAlert: true,
      }

    if (pathnameLocation?.includes('/furniture/product'))
      return {
        fadedComponent: refSearchBar?.current,
        showToolbarButtons: {
          UP: ['stores', 'cart'],
          DOWN: ['search', 'cart'],
        },
        hideAppBarAlert: true,
      }

    if (pathnameLocation?.includes('/furniture'))
      return {
        fadedComponent: refToolbar?.current,
        showToolbarButtons: {
          UP: ['stores', 'cart'],
          DOWN: ['stores', 'cart'],
        },
        subheaderId: '#header-filters-search',
        hideAppBarAlert: true,
      }

    if (pathnameLocation?.includes('/search'))
      return {
        fadedComponent: refToolbar?.current,
        showToolbarButtons: {
          UP: ['stores', 'cart'],
          DOWN: ['stores', 'cart'],
        },
        subheaderId: '#header-filters-search',
        hideAppBarAlert: true,
      }

    if (pathnameLocation?.includes('/cart'))
      return {
        fadedComponent: refToolbar?.current,
        showToolbarButtons: {
          UP: ['stores', 'search'],
          DOWN: ['stores', 'search'],
        },
        hideSearchBar: true,
        hideAppBarAlert: true,
      }

    if (pathnameLocation?.includes('/checkout'))
      return {
        fadedComponent: refToolbar?.current,
        showToolbarButtons: {
          UP: [],
          DOWN: [],
        },
        hideAppBarAlert: true,
      }

    if (pathnameLocation?.includes('/stores'))
      return {
        fadedComponent: refToolbar?.current,
        showToolbarButtons: {
          UP: ['stores', 'cart'],
          DOWN: ['stores', 'cart'],
        },
        hideSearchBar: true,
        hideAppBarAlert: true,
        subheaderId: '#header-find-stores',
      }

    return {
      fadedComponent: refToolbar?.current,
      showToolbarButtons: {
        UP: ['stores', 'cart'],
        DOWN: ['stores', 'cart'],
      },
      subheaderId: '#header-filters-search',
      hideAppBarAlert: true,
    }
  }

  const scrollEffect = ({ scrollPosition, scrollDirection }) => {
    const headerState = getHeaderStateByLocation(pathname) || {}
    const hideSearchBar = headerState?.hideSearchBar

    if (scrollPosition < 10) setHiddenAppBarAlert(false)
    if (scrollDirection === 'DOWN' && showSearch && hideSearchBar) setShowSearch(false)
  }
  const { pathname, scrollDirection, scrollPosition } = usePage({ scrollEffect })
  const previousScrollDirection = usePrevious(scrollDirection)
  const showTopBarSlider = shouldShowTopBarSlider(DisplaysOnList, pathname, hideTopBarSlider)

  useLayoutEffect(() => {
    const headerState = getHeaderStateByLocation(pathname) || {}
    const elMobileSearchWrapper = headerState?.fadedComponent
    const { callbackFunction, hideAppBarAlert } = headerState

    if (scrollDirection === 'UP') {
      if (elMobileSearchWrapper === refSearchBar?.current) {
        setShowSearch(true)
      } else {
        elMobileSearchWrapper?.classList?.remove('hidden')
        dispatch(setMobileShowMeganav(true))
      }
    } else if (scrollDirection === 'DOWN' && scrollPosition > 0 && previousScrollDirection !== scrollDirection) {
      if (elMobileSearchWrapper === refSearchBar?.current) {
        setShowSearch(false)
      } else {
        elMobileSearchWrapper?.classList?.add('hidden')
        dispatch(setMobileShowMeganav(false))
      }
      if (hideAppBarAlert && !isBodyLocked()) setHiddenAppBarAlert(true)
    }
    callbackFunction?.()
  }, [pathname, previousScrollDirection, scrollDirection, scrollPosition, dispatch])

  useLayoutEffect(() => {
    const headerElement = typeof document !== 'undefined' && document.querySelectorAll('header.mobile-only')?.[0]
    // eslint-disable-next-line no-undef
    const resizeObserver = new ResizeObserver(() => {
      if (typeof window !== 'undefined') {
        window.requestAnimationFrame(() => {
          const headerStateSubheaderId = getHeaderStateByLocation(pathname)?.subheaderId
          const headerFilterSearchElements =
            typeof document !== 'undefined' &&
            headerStateSubheaderId &&
            document.querySelectorAll(headerStateSubheaderId)

          const tabsElement = document.querySelector('#tabs-header-wrapper')
          const headerSize = headerElement?.offsetHeight ?? 0
          const subheaderOffsetHeight = tabsElement?.offsetHeight ?? 0

          if (headerFilterSearchElements?.length) {
            headerFilterSearchElements.forEach(headerFilterSearchElement => {
              headerFilterSearchElement.style.top = `${headerSize + subheaderOffsetHeight}px`
              headerFilterSearchElement.style.position = 'sticky'
            })
          }

          if (headerElement && document.body.style.paddingTop !== `${headerSize}px`) {
            document.body.style.paddingTop = `${headerSize}px`
          }
        })
      }
    })

    if (headerElement) resizeObserver.observe(headerElement)
    // eslint-disable-next-line consistent-return
    return () => resizeObserver?.disconnect()
  }, [pathname, scrollDirection])

  useLayoutEffect(() => {
    const headerStateContentWrapperStyles = getHeaderStateByLocation(pathname)?.contentWrapperStyles
    const headerFilterContentWrapper =
      typeof document !== 'undefined' && document.querySelectorAll('.content-wrapper')?.[0]
    if (headerFilterContentWrapper) {
      if (headerStateContentWrapperStyles) {
        Object.entries(headerStateContentWrapperStyles)?.forEach(([property, value]) => {
          headerFilterContentWrapper.style[property] = value
        })
      } else {
        headerFilterContentWrapper.style = {}
      }
    }
  }, [pathname])

  const isFavoriteActive = () => {
    // "window" is not available during Server-Side Rendering.
    if (typeof window !== 'undefined') {
      return window?.location?.pathname?.includes?.('/favorites')
    }
    return false
  }

  const toggleDrawer = () => setDrawer(currentDrawer => !currentDrawer)
  const btnAriaLabel = `${drawer ? 'close' : 'open'} menu`
  const iconSx = { fontSize: '2rem !important', color: 'primary.dark' }

  useEffect(() => {
    if (isCheckout || getHeaderStateByLocation(pathname)?.hideSearchBar) {
      setShowSearch(false)
    } else {
      setTimeout(() => {
        setShowSearch(true)
      }, theme.transitions.duration.leavingScreen)
    }
  }, [drawer, isCheckout, pathname, theme])
  return (
    <>
      <AppBar position="fixed" className="mobile-only" elevation={0} sx={{ zIndex: t => t.zIndex.drawer + 1 }}>
        {showTopBarSlider && <TopBarInfoSlider data={topBarData} isDrawerOpen={hiddenAppBarAlert} isMobile />}
        <StyledToolbar ref={refToolbar} id="mobile-toolbar">
          {!isCheckout ? (
            <>
              <LeftSideBtn size="large" edge="start" color="primary" aria-label={btnAriaLabel} onClick={toggleDrawer}>
                {drawer ? <CloseIcon /> : <MenuIcon />}
              </LeftSideBtn>
              {getHeaderStateByLocation(pathname)?.showToolbarButtons?.[scrollDirection]?.includes('stores') && (
                <MyStore />
              )}
              <LogoWrapper
                id="mobile-logo-header"
                data={{
                  InternalUrl: '/',
                  Title: 'Rooms To Go Home',
                }}
                trackingData={{ category: 'header', action: 'logo click', label: 'logo' }}
              >
                <RTGLogo uniqueNameForId="mobile-header" />
              </LogoWrapper>
              <IconButton
                component={ButtonLink}
                data={{
                  InternalUrl: '/favorites',
                  Title: 'Favorites',
                }}
                trackingData={{
                  category: 'header favorites',
                  action: 'favorites click',
                  label: 'go to favorites page',
                }}
                sx={{ ml: 2, padding: 0 }}
              >
                <Badge badgeContent={favorites.size} color="primary">
                  {isFavoriteActive() ? <ActiveFavoriteIcon sx={iconSx} /> : <FavoriteBorderOutlinedIcon sx={iconSx} />}
                </Badge>
              </IconButton>
              {getHeaderStateByLocation(pathname)?.showToolbarButtons?.[scrollDirection]?.includes('cart') && <Cart />}
              {getHeaderStateByLocation(pathname)?.showToolbarButtons?.[scrollDirection]?.includes('search') && (
                <IconButton
                  id="header-search-menu-btn"
                  component={Button}
                  onClick={() => setShowSearch(!showSearch)}
                  sx={{ ml: 2, minWidth: '32px!important' }}
                >
                  <SearchOutlinedIcon sx={iconSx} />
                </IconButton>
              )}
            </>
          ) : (
            <>
              <LogoWrapperCheckout
                id="mobile-logo-header"
                data={{
                  InternalUrl: '/',
                  Title: 'Rooms To Go Home',
                }}
                trackingData={{ category: 'header', action: 'logo click', label: 'logo' }}
              >
                <RTGLogo uniqueNameForId="mobile-header" />
              </LogoWrapperCheckout>
              <StyledCheckoutLabel>CHECKOUT</StyledCheckoutLabel>
            </>
          )}
        </StyledToolbar>
        <StyledMobileSearch ref={refSearchBar} showSearch={showSearch} />
        <MobileDrawer open={drawer} onDrawerClose={toggleDrawer} />
        {children}
      </AppBar>
    </>
  )
}

MobileAppBar.propTypes = {
  children: PropTypes.node,
  isCheckout: PropTypes.bool,
  hideTopBarSlider: PropTypes.bool,
}

export default MobileAppBar
